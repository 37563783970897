import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import './BlogRoll.scss';

class BlogRoll extends React.Component {
  constructor(props) {
    super(props);
    const justAll = new Set();
    justAll.add('All');
    this.state = { selectedFilter: justAll };
  }

  handleTagClick(tag) {
    const { selectedFilter } = this.state;
    const temp = new Set(selectedFilter);

    if (tag !== 'All') {
      temp.delete('All');
      if (temp.has(tag)) {
        temp.delete(tag);
      } else {
        temp.add(tag);
      }
    } else if (tag === 'All') {
      temp.clear();
      temp.add('All');
    }

    if (temp.size == 0) {
      temp.add('All');
    }

    this.setState({ selectedFilter: temp });
  }

  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const { selectedFilter } = this.state;
    const tags = data.allMarkdownRemark.group.map((x) => x.tag);
    tags.unshift('All');

    return (
      <div className="blogRoll">
        <div className="columns is-mobile blogroll--tags">
          <div className="column is-10">
            <div className="tags blog-scroll">
              {tags && tags.map((tag) => <button key={tag} type="button" onClick={() => this.handleTagClick(tag)} className={selectedFilter.has(tag) ? 'button is-rounded OrtomTag OrtomTag-active' : 'button is-rounded OrtomTag'}>{tag}</button>)}
            </div>
          </div>

          <div className="column is-2">
            <Link to="/RSS.xml">
              <p className="blogRoll--button RSS--button">
                RSS
              </p>
            </Link>
          </div>
        </div>

        {posts
          && posts.map(({ node: post }) => (
            <React.Fragment key={post.id}>
              {selectedFilter.has('All') || Array.from(selectedFilter).some((el) => post.frontmatter.tags.includes(el)) ? (
                <div className="columns blogRoll--index">
                  <div className="column is-4" key={`${post.id}date`}>
                    <h4 className="blogRoll--date">
                      {post.frontmatter.date}
                    </h4>
                  </div>
                  <div className="column is-8" key={`${post.id}title`}>
                    <h4 className="blogRoll--title">
                      {post.frontmatter.title}
                    </h4>
                    <p>
                      {post.excerpt}
                    </p>
                    <Link to={post.fields.slug}>
                      <p>
                        <span className="blogRoll--button">Read</span>
                        <span style={{ marginLeft: '8px' }}>→</span>
                      </p>
                    </Link>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } }
          fields: { draft: { eq: false } } 
        }
        ) {
          group(field: frontmatter___tags) {
            tag: fieldValue
          }
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                templateKey
                date(formatString: "DD.MM.YY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
